.confirm-overlay {
  @apply flex flex-col w-full items-center justify-center fixed bottom-0 top-0 right-0 left-0 bg-slate-700 bg-opacity-50 z-10 px-6;
}

.confirm-dialog {
  @apply p-6 bg-lightcolor-500  shadow-xl rounded-xl fixed left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 z-10;

  & .confirm-question {
    @apply font-normal text-lg text-warning-500 text-center;
  }

  & .confirm-buttons {
    @apply flex flex-row justify-around items-center mt-4 px-4;
  }

    & .confirm-btn {
      @apply py-1 px-6 rounded-md text-white text-lg font-semibold uppercase hover:scale-95 xs:text-base xs:px-4 shadow-3xl transition-all duration-200;
    }

  & .confirm-btn.del-btn {
    @apply bg-warning-500 mr-2;
  }

  & .confirm-btn.cancel-btn {
    @apply bg-primary-400;
  }
}
