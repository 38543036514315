.columns-wrapper {
  @apply w-full h-full flex flex-row justify-start pb-6;

  & .columns-add {
    @apply flex flex-col space-y-3 pr-12;
  }

  & .columns-add-bttn {
    @apply w-60 h-12 bg-lightcolor-500 bg-opacity-50 hover:bg-additional-300 hover:bg-opacity-70 drop-shadow-md rounded-md text-lg uppercase text-chocolate-500 flex flex-row justify-center items-center font-semibold hover:text-secondary-500 transition-all duration-200;
  }
}

.columns {
  height: calc(100vh - 261px);
}