.home {
  @apply flex flex-col h-auto;

  & .home__wrapper {
    @apply flex flex-row space-x-16 items-center py-16 xl:flex-col xl:space-x-0 xl:space-y-6 md:py-8;
  }

  & .home__heading {
    @apply text-4xl mb-8 md:mb-0;
  }

  & .home__heading > span {
    @apply md:block ;
  }

  & .home__text {
    @apply w-1/2 bg-white bg-opacity-60 xl:w-full xl:flex xl:flex-col xl:px-0 md:px-0 ;
  }

  & .home__heading_s {
    @apply text-primary-500 font-bold ;
  }

  & .home__heading_m {
    @apply text-secondary-500 text-6xl font-bold uppercase xs:text-5xl md:text-4xl;
  }

  & .home__heading_l {
    @apply text-secondary-500 text-8xl font-bold block uppercase md:text-7xl xs:text-5xl;
  }

  & .home__descr {
    @apply text-lg mt-2;
  }

  & .home__descr * li {
    @apply list-disc mx-6 text-secondary-500;
  }

  & .home__descr * span {
    @apply  text-black;
  }

  & .home__img {
    width: 60%;
  }
}

.home-video,
.home-team {
  @apply mt-16 w-full xs:mt-8;

  & .home-video__wrapper {
    @apply flex flex-col items-center;
  }

  & .home-video__heading,
  .home-team__heading {
    @apply mb-6 text-secondary-500 text-4xl text-center font-bold uppercase xs:text-2xl;
  }

  & .home-video__inner-wrapper {
    @apply w-full flex flex-row justify-around items-end xl:flex-col xl:items-center;
  }

  & .video__img {
    @apply w-1/5 h-1/2 xl:w-1/3;
  }

  & .video-frame {
      width: calc(100vw / 2);
      height: calc(100vw / 4);
    @media (max-width: 1200px) {
      width: calc(100vw - 20pt);
      height: calc((100vw - 10pt) / 2);
    }
  }

  & .team-members {
    @apply flex flex-row justify-between px-24 pb-16 lg:flex-col lg:items-center lg:px-0;
  }

  & .member__card {
    @apply p-2 flex flex-row w-1/3 3xl:flex-col xl:flex-row lg:flex-row lg:w-1/2 lg:items-center xs:w-full;
  }

  & .member__img {
    @apply w-36 h-36 mr-2 border-8 rounded-full border-additional-500 ;
  }

  & .member__text {
    @apply bg-white bg-opacity-100;
  }

  & .member__name {
    @apply text-additional-500 text-2xl font-bold lg:text-center;
  }

  & .duty-point {
    @apply text-additional-500 list-disc ml-4;
  }

  & .duty-point > span{
    @apply text-black;
  }
}