.signform {
  @apply flex flex-col  bg-white px-6 py-3 shadow-xl rounded;
  min-width: 25rem;
  min-height: 25rem;

  & .signform-item {
    @apply w-full text-center py-3;

    & .signform-header {
      @apply text-zinc-900 text-xl;
    }

    .signform-input {
      @apply border-zinc-500 w-full focus:border-amber-500 outline-0 rounded px-2 h-12;
      border-width: 3px;
    }

    & .signform-button {
      @apply bg-amber-500 px-4 py-2 rounded cursor-pointer w-full h-12 text-white text-xl font-bold shadow-3xl hover:scale-95 transition-all duration-200;
    }

    & .error-field {
      @apply h-3 text-red-400;
    }

    & .signform-footer-link {
      @apply px-2 text-amber-500 font-bold underline;
    }
  }
}
